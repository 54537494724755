import { createFeatureSelector, createSelector } from '@ngrx/store';
import { featureAdapter, State } from './state';
import { ISnippet } from '../../models';

export const getError = (state: State) => state.error;

export const getIsLoading = (state: State) => state.isLoading;

export const selectState = createFeatureSelector<State>('snippets');

export const selectAllItems =
  featureAdapter.getSelectors(selectState).selectAll;

export const selectError = createSelector(selectState, getError);

export const selectIsLoading = createSelector(selectState, getIsLoading);

export const selectSnippets = createSelector(
  selectAllItems,
  (snippetsGroups) => {
    let snippets: ISnippet[] = [];

    snippetsGroups.forEach((snippetsGroup) => {
      snippets = [...snippets, ...snippetsGroup.snippets_codes];
    });

    return snippets;
  },
);

export const selectSnippetsFilteredByIds = (idsToRemove?: number[]) =>
  createSelector(selectAllItems, (snippetsGroups) => {
    if (!idsToRemove?.length) {
      return snippetsGroups;
    }

    return snippetsGroups?.filter(({ id }) => !idsToRemove.includes(id));
  });

export const selectFlatSnippetsFilteredByIds = (idsToRemove?: number[]) =>
  createSelector(selectSnippetsFilteredByIds(idsToRemove), (snippetsGroups) => {
    let snippets: ISnippet[] = [];

    snippetsGroups.forEach((snippetsGroup) => {
      snippets = [...snippets, ...snippetsGroup.snippets_codes];
    });

    return snippets;
  });

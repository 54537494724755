import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { SnippetsService } from '../../services';

import * as fromActions from './actions';

@Injectable()
export class SnippetsStoreEffects {
  constructor(
    private dataService: SnippetsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadSnippets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(() => {
        return this.dataService.load().pipe(
          map((response: IResponseSuccess) => {
            return fromActions.loadSuccess({
              items: response.data,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);

            return of(fromActions.loadFailure(error));
          }),
        );
      }),
    ),
  );
}

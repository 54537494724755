import { createAction, props } from '@ngrx/store';
import { ISnippetGroup } from '../../models';

export const loadRequest = createAction('[Snippets] Load Request');

export const loadSuccess = createAction(
  '[Snippets] Load Success',
  props<{ items: ISnippetGroup[] }>(),
);

export const loadFailure = createAction(
  '[Snippets] Load Snippets Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Snippets] Reset State');

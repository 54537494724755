import { Action, createReducer, on } from '@ngrx/store';

import * as fromActions from './actions';
import * as fromState from './state';

export const reducer = createReducer(
  fromState.initialState,
  on(fromActions.loadRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadSuccess, (state, { items }) =>
    fromState.featureAdapter.setAll(items, {
      ...state,
      isLoading: false,
      error: null,
    }),
  ),
  on(fromActions.loadFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),

  on(fromActions.resetState, () => fromState.initialState),
);

export function snippetsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
